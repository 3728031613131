
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Zhou Wenye
   * Date: 4th of August 2021
   *
   * Summary: Need Help Page
   */
  import { Component, Vue } from 'vue-property-decorator';
  import AscxHelp from '@rdss-mono/ascx-help';
  @Component({
    components: {
      AscxHelp
    }
  })
  export default class NeedHelp extends Vue {
    private get helpChannels() {
      return [
        {
          id: '1',
          imgSrc: require('@/../assets/images/icons/call_us.svg'),
          title: this.$t('need-help.call.title'),
          description: this.$t('need-help.call.word'),
          note: this.$t('need-help.call.tel')
        },
        {
          id: '2',
          imgSrc: require('@/../assets/images/icons/email_us.svg'),
          title: this.$t('need-help.email.title'),
          description: this.$t('need-help.email.word')
        }
      ];
    }
  }
